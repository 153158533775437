import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../templates/LayoutService'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../styles/components/boxes.module.scss'
import { bannerBlock, bannerBlockHeadline, bannerBlockTagline, bannerBlockTitleSm, pageContentBlock } from '../styles/components/blocks.module.scss'
import '../styles/components/content.module.scss'

const PrivacyPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>Privacy Policy | Tampa Bay Boating Adventures</title>
        <meta name="description" content="View the privacy policy for TampaBayBoatingAdventures.com" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Friends talking on a party boat in Tampa Bay, FL."
                  src="../images/banners/friends-talking-on-party-boat.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>Our Privacy Policy</h1>
                <span className={bannerBlockTitleSm}>TBBA Values Your Privacy</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={pageContentBlock}>
              <h2>Privacy Policy</h2>
              <h3>Welcome to Tampa Bay Boating Adventures (the "Site").</h3>
              <p>We understand that privacy online is important to users of our Site, especially when conducting business. This statement governs our privacy policies with respect to those users of the Site ("Visitors") who visit without transacting business and Visitors who register to transact business on the Site and make use of the various services offered by Tampa Bay Boating Adventures (collectively, "Services") ("Authorized Customers").</p>
              <p>"Personally Identifiable Information" refers to any information that identifies or can be used to identify, contact, or locate the person to whom such information pertains, including, but not limited to, name, address, phone number, fax number, email address, financial profiles, social security number, and credit card information. Personally Identifiable Information does not include information that is collected anonymously (that is, without identification of the individual user) or demographic information not connected to an identified individual.</p>
              <hr />
              <h2>What Personally Identifiable Information is collected?</h2>
              <p>We may collect basic user profile information from all of our Visitors. We collect the following additional information from our Authorized Customers: the names, phone numbers and email addresses of Authorized Customers.</p>
              <hr />
              <h2>With whom may the information may be shared?</h2>
              <p>Personally Identifiable Information collected on this site will be shared only with employees within the Tampa Bay Boating Adventures company. Your information is not sold or leased to any 3rd party vendors.</p>
              <hr />
              <h2>How is Personally Identifiable Information stored?</h2>
              <p>Personally Identifiable Information collected by Tampa Bay Boating Adventures is securely stored and is not accessible to third parties or employees of Tampa Bay Boating Adventures except for use as indicated above.</p>
              <hr />
              <h2>What choices are available to Visitors regarding collection, use and distribution of the information?</h2>
              <p>Visitors and Authorized Customers may opt out of receiving unsolicited information from or being contacted by us and/or our vendors and affiliated agencies by responding to emails as instructed, or by contacting us.</p>
              <hr />
              <h2>Are Cookies Used on the Site?</h2>
              <p>Cookies are used for a variety of reasons. We use Cookies to obtain information about the preferences of our Visitors and the services they select. We also use Cookies for security purposes to protect our Authorized Customers. For example, if an Authorized Customer is logged on and the site is unused for more than 15 minutes, we will automatically log the Authorized Customer off.</p>
              <hr />
              <h2>How does Tampa Bay Boating Adventures use login information?</h2>
              <p>Tampa Bay Boating Adventures uses login information, including, but not limited to, IP addresses, ISPs, and browser types, to analyze trends, administer the Site, track a user's movement and use, and gather broad demographic information.</p>
              <hr />
              <h2>How does the Site keep Personally Identifiable Information secure?</h2>
              <p>All of our employees are familiar with our security policy and practices. The Personally Identifiable Information of our Visitors and Authorized Customers is only accessible to a limited number of qualified employees who are given a password in order to gain access to the information. We audit our security systems and processes on a regular basis. Sensitive information, such as credit card numbers or social security numbers, is protected by encryption protocols, in place to protect information sent over the Internet.</p>
              <hr />
              <h2>How can Visitors correct any inaccuracies in Personally Identifiable Information?</h2>
              <p>Visitors and Authorized Customers may contact us to update Personally Identifiable Information about them or to correct any inaccuracies by emailing us. Do not send sensitive information via email.</p>
              <hr />
              <h2>What happens if the Privacy Policy Changes?</h2>
              <p>We will let our Visitors and Authorized Customers know about changes to our privacy policy by posting such changes on the Site.</p>
              <hr />
              <h2>Links:</h2>
              <p>This web site contains links to other web sites. Please note that when you click on one of these links, you are moving to another web site. We encourage you to read the privacy statements of these linked sites as their privacy policies may differ from ours.</p>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default PrivacyPage